<template>
    <div class="products-product">
        <ul>
            <li class="products-list-product" v-for="product in products" :key="product.id">
                <router-link :to="`/products/${product.id}`" class="products-list-product-link">
                    <img class="products-list-image" v-bind:src="product.image" v-bind:alt="product.label" />
                    <h2 class="products-list-label">{{ product.label }}</h2>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        products: {
            type: Array,
            default: () => [],
        },
    },
};
</script>