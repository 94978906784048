//import { createRouter, createWebHistory } from 'vue-router';
import Vue from "vue";
import VueRouter from 'vue-router';
import HomeView from "@/views/HomeView.vue";
import ProductsListView from './views/ProductsListView.vue'
import ProductsDetailView from './views/ProductsDetailView.vue';
import BladesDetailView from './views/BladesDetailView.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import LoginView from './views/LoginView';
import RegisterView from './views/RegisterView';
import LogoutView from './views/LogoutView';
import PasswordResetView from './views/PasswordResetView';


Vue.use(VueRouter);

export default new VueRouter({
    //mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "HomeView",
            component: HomeView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'LoginView',
            component: LoginView,
            meta: {
                requiresLogged: true
            }
        },
        {
            path: '/reset',
            name: 'PasswordResetView',
            component: PasswordResetView
        },
        {
            path: '/registration',
            name: 'RegisterView',
            component: RegisterView,
            meta: {
                requiresLogged: true
            }
        },
        {
            path: "/products",
            component: ProductsListView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/products/:productId",
            props: true,
            component: ProductsDetailView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/blades",
            name: "blades",
            component: BladesDetailView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/logout',
            name: 'LogoutView',
            component: LogoutView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/:catchAll(.*)*',
            name: "PageNotFound",
            component: PageNotFound,
        },
    ]
});
