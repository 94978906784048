<template>
    <div class="home-view">
        <div class="btn-settings-menu" :class="{ active: isActive }" @click="isActive = !isActive">
            <svg class="btn-settings-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g transform="matrix(1 0 0 -1 0 1920)">
                    <path d="M500,930c-18.9,0-39.9,1.5-63.1,4.5l-32.4,129.2c-31.4,8.5-61,20.8-88.6,36.9l-114.4-68.5c-33.6,25.7-63.4,55.4-89.4,89l68.5,114.4c-16.1,27.9-28.3,57.5-36.5,89l-129.6,32.4c-3,23.2-4.5,44.3-4.5,63.1c0,18.9,1.5,39.9,4.5,63.1l129.6,32.4c8.2,31.2,20.4,60.7,36.5,88.6l-68.5,114.4c26,33.6,55.8,63.4,89.4,89.4l114.4-68.5c27.9,15.9,57.4,28,88.6,36.5l32.4,129.6c23.5,3,44.6,4.5,63.1,4.5c18.9,0,39.9-1.5,63.1-4.5l32.4-129.6c31.4-8.2,61.1-20.4,89-36.5l114.4,68.5c33.6-26,63.3-55.8,89-89.4l-68.5-114.4c16.1-27.9,28.4-57.4,36.9-88.6l129.6-32.4c2.7-23.5,4.1-44.6,4.1-63.1c0-18.6-1.4-39.6-4.1-63.1l-129.6-32.4c-8.5-31.4-20.8-61.1-36.9-89l68.9-114c-26.2-33.9-56-63.7-89.4-89.4l-114.4,68.5c-27.6-16.1-57.3-28.4-89-36.9l-32.4-129.2C539.9,931.5,518.9,930,500,930z M500,1248.6c23.2,0,45.4,4.5,66.6,13.5c21.2,9,39.4,21.2,54.7,36.5s27.5,33.6,36.5,54.7c9,21.2,13.5,43.4,13.5,66.6c0,23.2-4.5,45.4-13.5,66.6c-9,21.2-21.2,39.4-36.5,54.7s-33.6,27.5-54.7,36.5c-21.2,9-43.4,13.5-66.6,13.5c-30.9,0-59.5-7.7-85.9-23c-26.4-15.3-47.2-36.2-62.5-62.5c-15.3-26.4-23-55.1-23-86.1c0-31,7.7-59.7,23-85.9c15.3-26.2,36.2-47,62.5-62.3C440.5,1256.3,469.1,1248.6,500,1248.6z"/>
                </g>
            </svg>
            <div class="settings-sidebar-wrap">
                <div class="settings-sidebar-slave">
                    <div class="login-navigation">
                        <ul>
                          <!--<li v-if="accessToken==null"><router-link :to = "{ name:'RegisterView' }">REGISTER</router-link></li>-->
                          <li v-if="accessToken==null"><router-link :to = "{ name:'LoginView' }">LOGIN</router-link></li>
                          <li v-if="accessToken!=null"><router-link :to = "{ name:'LogoutView' }">LOGOUT</router-link></li>
                          <!--<li><router-link :to = "{ name:'blades' }">BLADES</router-link></li>
                          <li><router-link :to = "{ name:'HomeView' }" exact>HOME</router-link></li>-->
                        </ul>
                    </div>
                    <!--
                    <div class="language-menu-wrap">
                        <div class="locale-changer">
                            <ul>
                                <li v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</li>
                            </ul>
                        </div>
                    </div>
                    -->
                    <div class="text-box-bottom">
                        <div class="text-box-bottom-inner">
                            <h3 class="imprint-title">{{ $t("home.imprint_title") }}</h3>
                            <p class="imprint-text">{{ $t("home.imprint_text") }}</p>

                            <div class="logversions-wrapper" v-for="(log,index) in this.$root.confsByLogVersions" :key="index" style="text-align: left;">
                                <div v-for="logversion in log" :key="logversion.id">
                                    <h3 class="logversions-title">Version:&nbsp;{{ logversion.version_number }}</h3>
                                    <p>Release Date:&nbsp;{{ logversion.date_of_change }}</p>
                                    <div class="logversions-notes-wrap">Release Note:&nbsp;
                                        <div class="logversions-notes">{{ logversion.message }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="contact-wrap">
                                <h3 class="contact-title">{{ $t("home.contact_title") }}</h3>
                                <p class="contact-text">{{ $t("home.contact_text") }}</p>
                                <a class="contact-link" href="tel:+41629193131">{{ $t("home.contact_phone") }}</a>
                                <a class="contact-link" href="mailto:swisstec@daetwyler.com">{{ $t("home.contact_email") }}</a>
                                <a class="contact-link" href="https://wwww,swisstec.daetwyler.com" rel="noopener noreferrer">{{ $t("home.contact_website") }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HeaderImage></HeaderImage>
        <div class="content-wrap">
            <h1 class="main-title">{{ $t("home.welcome") }}.</h1>
            <h2 class="sub-title">{{ $t("home.welcome_sub") }}</h2>
            <img class="main-logo" alt="daetwyler logo" src="../assets/logo.jpg">
        </div>
        <BottomNavBar></BottomNavBar>
    </div>
</template>

<script>
    //import NavBar from '../components/Navbar'
    import HeaderImage from '../components/HeaderImage'
    import BottomNavBar from '../components/BottomNavbar'
    import { mapState } from 'vuex'
    export default {
        //name: 'locale-changer',
        name: 'HomeView',
        components: {
            HeaderImage,
            BottomNavBar
        },
        computed: mapState(['accessToken']),
        data () {
            return {
                isActive: false,
                langs: ['de', 'fr', 'en']
            }
        }
    }
</script>
<style>
/* 
    orange: #ed6f2d 
    grey: #ededed
    grey-font: #4a4a4a
*/

.home-view {
    overflow: hidden;
}

.btn-settings-menu {
    position: relative;
}

.btn-settings-menu .btn-settings-icon {
    width: 30px;
    position: absolute;
    z-index: 11;
    right: 20px;
    top: 20px;
}

.settings-sidebar-wrap {
    position: fixed;
    z-index: 10;
    right: -100%;
    background-color: #4a4a4a;
    height: 100%;
    width: 50%;
    -webkit-transition: right .3s ease-in-out;
    -moz-transition: right .3s ease-in-out;
    -o-transition: right .3s ease-in-out;
    transition: right .3s ease-in-out;
}

.btn-settings-menu.active {
    height: 100%;
}

.btn-settings-menu.active .btn-settings-icon path {
    fill: #ffffff;
    -webkit-transition: fill .5s ease-in-out;
    -moz-transition: fill .5s ease-in-out;
    -o-transition: fill .5s ease-in-out;
    transition: fill .5s ease-in-out;
}

.btn-settings-menu.active .settings-sidebar-wrap {
    right: 0;
}

.settings-sidebar-wrap .settings-sidebar-slave {
    padding: 20px;
    height: 100%;
    display: block;
    box-sizing: border-box;
}

.settings-sidebar-wrap .language-menu-wrap .locale-changer {
    margin: 0;
    padding: 0;
}

.settings-sidebar-wrap .language-menu-wrap .locale-changer li {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    color: #fff;
    text-transform: uppercase;
    margin: 10px;
}

.settings-sidebar-wrap .login-navigation {
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}
.settings-sidebar-wrap .login-navigation ul {
    list-style: none;
    margin: 0 80px 0 0;
    padding: 0;
    text-align: left;
    color: white;
}
.settings-sidebar-wrap .login-navigation ul li {
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 5px 0;
}
.settings-sidebar-wrap .login-navigation:after {
    content: '';
    display: block;
    clear: both;
}
.settings-sidebar-wrap .login-navigation ul li a {
    text-decoration: none;
    font-family: 'Archivo', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: white;
    padding: 10px;
    border-radius: 7px;
    background-color: #ed6f2d;
}
.settings-sidebar-wrap .login-navigation ul li a:hover {
    background-color: #ed6f2d;
}
.settings-sidebar-wrap .login-navigation .router-link-active {
    background-color: black;
}

.settings-sidebar-wrap .text-box-bottom {
    border-top: 1px solid #ed6f2d;
    bottom: 40px;
    position: absolute;
    color: #ffffff;
    text-align: left;
    width: 90%;
}

.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner {
    width: 100%;
    display: block;
    box-sizing: border-box;
}

.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .imprint-title,
.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .logversions-title,
.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .contact-title {
    margin-bottom: 5px;
}

.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .imprint-text {
    margin-top: 0;
}

.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .logversions-wrapper .logversions-notes-wrap {
    margin: 5px 0;
}

.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .logversions-wrapper .logversions-notes {
    white-space: pre-line;
    line-height: 1.2;
    font-size: 12px;
    margin-top: 4px;
}

.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .logversions-wrapper p {
    margin: 0;
}
.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .logversions-wrapper,
.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .contact-wrap {
    margin-top: 40px;
}

.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .contact-text {
    margin: 0;
}

.settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .contact-link {
    margin: 0;
    display: block;
    text-decoration: none;
    color: #fff;
}

.content-wrap {
    width: 96%;
    display: block;
    padding: 40px 2%;
}

.main-title,
.sub-title {
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
}

.main-title {
    font-size: 70px;
    margin-top: 0;
    margin-bottom: 10px;
}

.sub-title {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 60px;
}

@media screen and (max-width: 1124px) {

    .main-title {
        font-size: 50px;
    }
}

@media screen and (max-width: 700px) {

    .main-title {
        font-size: 30px;
    }

    .sub-title {
        font-size: 20px;
    }

    .settings-sidebar-wrap {
        width: 75%;
    }

    .settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .imprint-title,
    .settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .logversions-title,
    .settings-sidebar-wrap .text-box-bottom .text-box-bottom-inner .contact-title {
        font-size: 16px;
    }
}

</style>