<template>
    <div class="blades-detail product-detail">
        <div class="blades-header-wrap">
            <img class="header-img-blades" alt="header" src="../assets/svg/blades_header.svg">
            <h2 class="product-static-title">CONFIGURATIONS</h2>
        </div>

        <vsa-list v-for="(product,index) in this.$root.confsByBladeGroup" :key="index" style="text-align: left;">
            <vsa-item v-for="configuration in product" :key="configuration.id">
                <vsa-heading>
                    <h3>{{ configuration.label }}</h3>
                </vsa-heading>

                <vsa-content>
                    <vsa-list>

                        <span v-if="configuration.technical_drawing_image_url">
                            <vsa-item>
                                <vsa-heading>
                                    <h4>Technical drawing</h4>
                                </vsa-heading>
                                <vsa-content>
                                    <img class="technical-drawing-img" v-bind:src="configuration.technical_drawing_image_url" v-bind:alt="configuration.label" width="200" />
                                </vsa-content>
                            </vsa-item>
                        </span>

                        <span v-if="configuration.sample_information">
                            <vsa-item>
                                <vsa-heading>
                                    <h4>Advantages</h4>
                                </vsa-heading>
                                <vsa-content>
                                    <p class="advantages-wrapper">{{ configuration.sample_information }}</p>
                                </vsa-content>
                            </vsa-item>
                        </span>


                        <vsa-item>
                            <vsa-heading>
                                <h4>Products</h4>
                            </vsa-heading>
                            <vsa-content>
                                <div v-for="prod in configuration.products" :key="prod.id">
                                    <!-- show product once only -->
                                    <div v-for="(prodname, index) in prod" :key="index">

                                        <span v-if='index === 0'>
                                            <router-link @click.native="$scrollToTop" class="product-link" :key="prodname.absolute_url" :to="prodname.absolute_url"><h4>{{ prodname.product_name }}</h4></router-link>
                                                <!-- change request: disable for linking
                                                    <div v-for="degree_nr in prodname.degree_name" :key="degree_nr.id">
                                                        <span v-if="degree_nr.product_degree_name">
                                                            <p class="degree-nr">{{ degree_nr.product_degree_name }}&nbsp;°</p>
                                                        </span>
                                                    </div>
                                                -->
                                        </span>
                                    </div>
                                </div>
                            </vsa-content>
                        </vsa-item>

                    </vsa-list>

                </vsa-content>
            </vsa-item>
        </vsa-list>
        <BottomNavBar></BottomNavBar>
    </div>
</template>

<script>
    import BottomNavBar from '../components/BottomNavbar'
    export default {
        components: {
            BottomNavBar
        },
    }
</script>

<style>
    /* 
        orange: #ed6f2d 
        grey: #ededed
        grey-font: #4a4a4a
    */

    .blades-header-wrap {
        width: 100%;
        display: block;
        background-color: #ededed;
        border-bottom: 2px solid #ed6f2d;
    }
    .header-img-blades {
        width: 100%;
    }
    .product-link {
        color: #000;
    }

</style>
