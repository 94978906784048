<template>
    <div id="app">
        <div class="changes-update-btn-wrap" v-if="updateExists">
            <button class="changes-update-btn" @click="refreshApp">New version available! Click to update</button>
        </div>
        <!--<PWAPrompt />-->
        <div class="list-view">
            <router-view></router-view>
        </div>
        <div class="bottom-spacer"></div>
    </div>
</template>

<script>
//import update from './mixins/update'
//import PWAPrompt from './components/PWAPrompt.vue'

export default {
    name: 'App',
    mounted() {
        document.title = "MDC Doctor Blades";
    },
    data () {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        };
    },
    components: {
        //PWAPrompt,
    },
    //mixins: [update],
    created () {
        // Listen for swUpdated event and display refresh snackbar as required.
        document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
        // Refresh all open app tabs when a new service worker is installed.
        if (navigator.serviceWorker) {
            console.log('navigator.serviceWorker');
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },
    methods: {
        showRefreshUI (e) {
            console.log('showRefreshUI');
            // Display a button inviting the user to refresh/reload the app due
            // to an app update being available.
            // The new service worker is installed, but not yet active.
            // Store the ServiceWorkerRegistration instance for later use.
            this.registration = e.detail;
            this.updateExists = true;
        },
        refreshApp () {
            // Handle a user tap on the update app button.
            this.updateExists = false;
            // Protect against missing registration.waiting.
            if (!this.registration || !this.registration.waiting) { return; }
            this.registration.waiting.postMessage('skipWaiting');
            //this.registration.waiting.postMessage({ action: 'skipWaiting' });
        },
    },
}
</script>

<style>
/* 
    orange: #ed6f2d 
    grey: #ededed
    grey-font: #4a4a4a
*/
@font-face {
    font-family: 'Archivo';
    src: url('./assets/fonts/Archivo-Regular.eot');
    src: url('./assets/fonts/Archivo-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Archivo-Regular.woff2') format('woff2'),
        url('./assets/fonts/Archivo-Regular.woff') format('woff'),
        url('./assets/fonts/Archivo-Regular.ttf') format('truetype'),
        url('./assets/fonts/Archivo-Regular.svg#Archivo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archivo';
    src: url('./assets/fonts/Archivo-Thin.eot');
    src: url('./assets/fonts/Archivo-Thin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Archivo-Thin.woff2') format('woff2'),
        url('./assets/fonts/Archivo-Thin.woff') format('woff'),
        url('./assets/fonts/Archivo-Thin.ttf') format('truetype'),
        url('./assets/fonts/Archivo-Thin.svg#Archivo-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Archivo';
    src: url('./assets/fonts/Archivo-Bold.eot');
    src: url('./assets/fonts/Archivo-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/Archivo-Bold.woff2') format('woff2'),
        url('./assets/fonts/Archivo-Bold.woff') format('woff'),
        url('./assets/fonts/Archivo-Bold.ttf') format('truetype'),
        url('./assets/fonts/Archivo-Bold.svg#Archivo-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
}

ul,
li,
ol {
    margin: 0;
    padding: 0;
}

form input {
    display: block
}

#app {
    font-family: Archivo, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #4a4a4a;
    margin: 0;
    padding-bottom: 100px;
}

.main-logo {
    width: 100%;
    max-width: 400px;
    margin: 50px auto auto;
}

.vsa-list {
    --vsa-max-width: 100% !important;
}

.w-80,
.w-75,
.w-66,
.w-50,
.w-33,
.w-25,
.w-20 {
    display: inline-block;
    box-sizing: border-box;
}

.w-80 {
    width: 80%;
}

.w-75 {
    width: 75%;
}

.w-66 {
    width: 66.6666%;
}

.w-50 {
    width: 50%;
}

.w-33 {
    width: 33.33333%;
}

.w-25 {
    width: 25%;
}

.w-20 {
    width: 20%;
}

.txt-linebreak {
   white-space: pre-line
}

.orange-btn {
    border: 1px solid #ed6f2d;
    border-radius: 40px;
    padding: 4px 20px;
    line-height: 2;
    min-width: 300px;
    display: block;
    box-sizing: border-box;
    margin: 20px auto auto auto;
    background-color: #ed6f2d;
    color: #ffffff;
    cursor: pointer;
}

.transparent-btn {
    background-color: transparent;
    display: block;
    margin: 40px auto;
    border: 0;
}

.transparent-btn a {
    display: block;
    color: #4a4a4a;
}

.base-form {
    margin: 0;
    padding: 0;
}

.base-form input {
    border: 1px solid #ed6f2d;
    background-color: transparent;
    border-radius: 40px;
    padding: 4px 20px;
    line-height: 2;
    min-width: 300px;
    display: block;
    margin: auto;
    box-sizing: border-box;
}

.base-form input.error {
    border: 2px solid red;
}

.form-lg {
    text-align: center;
    color: white;
    padding: 0 4%;
    margin: 20px auto 40px;
    width: 92%;
}

.formerror {
    color: red;
    font-size: 20px;
}

.form-lg form label,
.form-lg form input {
    text-align: center;
    display: block;
    color: #4a4a4a;
    margin: 10px auto;
}

.form-lg form label {
    margin: 20px auto 10px;
}

.products-list-product {
    margin: 0 1% 20px 1%;
    width: 18%;
    display: inline-block;
    vertical-align: bottom;
    box-sizing: border-box;
}

.products-list-product .products-list-product-link {
    color: #4a4a4a;
    text-decoration: none;
}

.products-list-image {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #ededed;
    border-bottom: 0;
}

.products-list-label {
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #ededed;
    border-top: 0;
    margin: 0;
    padding: 20px;
    line-height: 1;
    font-size: 24px;
}

.bottom-spacer {
    position: relative;
    width: 100%;
    background-color: transparent;
    height: 200px;
}

.main-bottom-navigation {
    position: fixed;
    z-index: 9;
    left: 0;
    bottom: 0;
    background-color: white;
    border-top: 2px solid #ed6f2d;
    height: 140px;
    width: 100%;
}

.main-bottom-navigation .main-bottom-navigation-link {
    padding: 20px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    width: 33.333%;
}

.main-bottom-navigation .main-bottom-navigation-icon {
    width: 80px;
}

.changes-update-btn-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    padding: 20px;
    display: block;
    height: 80px;
    box-sizing: border-box;
    background-color: #4d4d4d;
    border-bottom: 1px solid #ed6f2d;
}

.changes-update-btn {
    padding: 4px 20px;
    background-color: #ed6f2d;
    color: white;
    border: 1px solid #ededed;
    border-radius: 40px;
    line-height: 2;
    display: block;
    box-sizing: border-box;
    margin: auto;
    cursor: pointer;
}

.btn-inline-block {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    margin-top: 10px !important;
}

@media screen and (max-width: 1560px) {

    .products-list-product {
        width: 23%;
    }

    .products-list-label {
        font-size: 20px;
    }
}

@media screen and (max-width: 1224px) {

    .products-list-product {
        width: 31.3333%;
    }
}

@media screen and (max-width: 900px) {

    .products-list-product {
        width: 48%;
    }
}

@media screen and (max-width: 700px) {

    .main-logo {
        max-width: 300px;
    }

    .products-list-label {
        font-size: 18px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .bottom-spacer {
        height: 120px;
    }

    .main-bottom-navigation {
        height: 110px;
    }

    .main-bottom-navigation .main-bottom-navigation-link {
        padding: 20px 10px;
    }

    .main-bottom-navigation .main-bottom-navigation-icon {
        width: 60px;
    }
}

@media screen and (max-width: 500px) {

    .main-logo {
        max-width: 200px;
    }

    .products-list-product {
        margin-left: 1%;
        margin-right: 1%;
        width: 98%;
    }
}

@media screen and (max-width: 400px) {

    .vsa-list {
        --vsa-min-width: 280px !important;
    }
}
</style>
