<template>
    <div class="base-form reset-form">
        <HeaderImage></HeaderImage>
        <div class="content-wrap">
            <h3 class="sub-title">{{ $t("reset.reset_instruction_title") }}</h3>
            <p class="txt-linebreak">{{ $t("reset.reset_instruction_text") }}</p>
            <template v-if="emailLoading">loading...</template>
            <template v-else-if="!emailCompleted">
                <div class="form-lg">
                    <form @submit.prevent="submit">
                        <label for="email">Email</label>
                        <input v-model="inputs.email" type="text" id="email" placeholder="Email" :class="{ error: emailError }">
                    </form>
                    <h2 class="formerror" v-show="emailError">{{ $t("reset.reset_instruction_error_text") }}</h2>
                    <button @click="sendPasswordResetEmail(inputs)" class="orange-btn">{{ $t("reset.reset_send_btn_text") }}</button>
                </div>
            </template>
            <template v-else>
                <div class="txt-linebreak">{{ $t("reset.reset_instruction_success_text") }}</div>
            </template>

            <div class="footer-links-wrap">
                <form v-on:submit.prevent="loginUser">
                    <button class="transparent-btn back-home-btn">
                        <router-link :to = "{ name:'LoginView' }">{{ $t("reset.reset_back_to_home") }}</router-link>
                    </button>
                </form>
            </div>
            <img class="main-logo" alt="daetwyler logo" src="../assets/logo.jpg">
        </div>
    </div>
</template>

<script>
    import HeaderImage from '../components/HeaderImage'
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'reset-password-view',
        components: {
            HeaderImage
        },

        data() {
            return { inputs: { email: '' } };
        },
        computed: mapState('password', [
            'emailCompleted',
            'emailError',
            'emailLoading',
        ]),
        methods: mapActions('password', [
            'sendPasswordResetEmail',
            'clearEmailStatus',
        ]),
        beforeRouteLeave(to, from, next) {
            this.clearEmailStatus();
            next();
        },

    }
</script>

<style>
.sub-title {
    margin-bottom: 30px;
}
</style>