<template>
    <div id="register-view" class="base-form reset-form">
        <HeaderImage></HeaderImage>
        <div class="content-wrap">
            <h1>{{ $t("register.register_create_title") }}</h1>
            <template v-if="registrationLoading">loading...</template>
            <template v-else-if="!hasBeenSubmitted">
                <div class="form-lg">
                    <form @submit.prevent="submitRegistration" novalidate>
                        <div class="form-group">
                            <label for="first_name">{{ $t("register.register_form_first_name") }} <span class="required">*</span></label>
                            <input id="first_name" v-model="inputs.first_name" class="form-control" type="text" :placeholder="$t('register.register_form_first_name')" v-model.trim="inputs.first_name">
                            <h2 class="formerror" v-for="(error, index) of v$.inputs.first_name.$errors" :key="index">{{ error.$message }}</h2>
                        </div>
                        <div class="form-group">
                            <label for="last_name">{{ $t("register.register_form_last_name") }} <span class="required">*</span></label>
                            <input id="last_name" v-model="inputs.last_name" class="form-control" type="text" :placeholder="$t('register.register_form_last_name')" v-model.trim="inputs.last_name">
                            <h2 class="formerror" v-for="(error, index) of v$.inputs.last_name.$errors" :key="index">{{ error.$message }}</h2>
                        </div>
                        <div class="form-group">
                            <label for="company">{{ $t("register.register_form_company") }} <span class="required">*</span></label>
                            <input id="company" v-model="inputs.company" class="form-control" type="text" :placeholder="$t('register.register_form_company')" v-model.trim="inputs.company">
                            <h2 class="formerror" v-for="(error, index) of v$.inputs.company.$errors" :key="index">{{ error.$message }}</h2>
                        </div>
                        <div class="form-group">
                            <label for="address">{{ $t("register.register_form_address") }} <span class="required">*</span></label>
                            <input id="address" v-model="inputs.address" class="form-control" type="text" :placeholder="$t('register.register_form_address')" v-model.trim="inputs.address">
                            <h2 class="formerror" v-for="(error, index) of v$.inputs.address.$errors" :key="index">{{ error.$message }}</h2>
                        </div>
                        <div class="form-group">
                            <label for="phone">{{ $t("register.register_form_phone") }} <span class="required">*</span></label>
                            <input id="phone" v-model="inputs.phone" class="form-control" type="text" :placeholder="$t('register.register_form_phone')" v-model.trim="inputs.phone">
                            <h2 class="formerror" v-for="(error, index) of v$.inputs.phone.$errors" :key="index">{{ error.$message }}</h2>
                        </div>
                        <div class="form-group">
                            <label for="email">{{ $t("register.register_form_email") }} <span class="required">*</span></label>
                            <input id="email" v-model="inputs.email" class="form-control" type="text" :placeholder="$t('register.register_form_email')" v-model.trim="inputs.email">
                            <h2 class="formerror" v-for="(error, index) of v$.inputs.email.$errors" :key="index">
                                <!--{{ capitalizeFirstLetter(error.$property) }}--> {{ error.$message }}
                            </h2>
                        </div>

                        <div class="form-group">
                            <label for="password1">{{ $t("register.register_form_password") }} <span class="required">*</span></label>
                            <input class="form-control" v-model="inputs.password1" type="text" :placeholder="$t('register.register_form_password')" value="" autocomplete="off" v-model.trim="inputs.password1"/>
                            <div v-if="v$.inputs.password1.$error">
                                <h2 class="formerror" v-for="(error, index) of v$.inputs.password1.$errors" :key="index">{{ error.$message }}</h2>
                                <h2 class="formerror" v-if="!v$.inputs.password1.valid">
                                    {{ $t("register.register_form_email_format_txt") }}
                                </h2>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password2">{{ $t("register.register_form_confirm_password") }} <span class="required">*</span></label>
                            <input class="form-control" v-model="inputs.password2" type="text" :placeholder="$t('register.register_form_confirm_password')" name="password2" v-model.trim="inputs.password2"/>
                            <h2 class="formerror" v-for="(error, index) of v$.inputs.password2.$errors" :key="index">{{ error.$message }}</h2>
                        </div>
                         <button type="submit" class="orange-btn" value="Register">
                             {{ $t("reset.reset_send_btn_text") }}
                         </button>
                    </form>
                </div>
            </template>
            <template v-else>
                <div class="registration-success-text">{{ $t("register.register_complete_text") }}</div>
            </template>
            <div class="footer-links-wrap">
                <form v-on:submit.prevent="loginUser">
                    <p>{{ $t("register.register_already_have_an_account") }}</p>
                    <button class="transparent-btn back-home-btn btn-inline-block">
                        <router-link :to = "{ name:'LoginView' }">Login</router-link>
                    </button>
                    <span class="btn-inline-block">  |  </span>
                    <button class="transparent-btn reset-psw-btn btn-inline-block">
                        <router-link :to = "{ name:'PasswordResetView' }">{{ $t("register.register_reset_password") }}</router-link>
                    </button>
                </form>
            </div>
            <img class="main-logo" alt="daetwyler logo" src="../assets/logo.jpg">
        </div>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, numeric, minLength, sameAs } from '@vuelidate/validators';

import HeaderImage from '../components/HeaderImage';
import { mapState, mapActions } from 'vuex';

const passwordValidators = {
    minLength: minLength(9),
    containsUppercase: function(value) {
        return /[A-Z]/.test(value)
    },
    containsLowercase: function(value) {
        return /[a-z]/.test(value)
    },
    containsNumber: function(value) {
        return /[0-9]/.test(value)
    },
    containsSpecial: function(value) {
        return /[#?!@$%^&*-]/.test(value)
    },
}

export default {
    name: 'register-view',
    components: {
        HeaderImage
    },
    setup () {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            inputs: {
                first_name: '',
                last_name: '',
                company: '',
                address: '',
                phone: '',
                email: '',
                password1: '',
                password2: ''
            },
            hasBeenSubmitted: false
        };
    },
    validations() {
        return {
            inputs: {
                first_name: { required },
                last_name: { required },
                company: { required },
                address: { required },
                phone:  { required, numeric },
                email: { email, required },
                password1: {
                    required,
                    ...passwordValidators
                },
                password2: { required, sameAsPassword: sameAs(this.inputs.password1)}
            },
        };
    },
    computed: mapState('signup', [
        'registrationCompleted',
        'registrationError',
        'registrationLoading',
    ]),
    methods: {
        ...mapActions('signup', [
            'createAccount',
            'clearRegistrationStatus',
        ]),
        submitRegistration() {
            this.v$.$touch();
            if (this.v$.$error) return;
            this.createAccount(this.inputs);
            this.hasBeenSubmitted = true;
            console.log('Form validated');
        }
        /*
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        */
    },
    /*
    beforeRouteLeave(to, from, next) {
        this.clearRegistrationStatus();
        next();
    },
    */
};
</script>

<style>
.registration-success-text {
    margin-bottom: 100px;
}
</style>

