<template>
    <div class="product-detail">
        <div v-for="(group, id) in this.$root.confsByProductGroup" :key="id" style="text-align: left;">
            <div v-if="id === productId">
                <div v-for="product in group" :key="product.id">
                    <div class="product-header-img" v-bind:style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                    <h1 class="product-main-title">{{ product.label }}</h1>
                    <h2 class="product-static-title">Available Configurations</h2>

                    <vsa-list v-for="confgroup in product.grouped_by_configuration" :key="confgroup.id" class="grouped_by_configuration">
                        <vsa-item>
                            <!-- show configuration and technical drawing once only -->
                            <vsa-heading v-for="(modulethicknessthrough, index) in confgroup" :key="index">
                                <h3 v-if='index === 0'>{{ modulethicknessthrough.configuration_name }}</h3>
                            </vsa-heading>

                            <vsa-content>

                                <vsa-list v-for="modulethicknessthrough in confgroup" :key="modulethicknessthrough.id">

                                    <!-- show text_module_thickness once only -->
                                    <span v-for="(text_module_thickness, index) in modulethicknessthrough.p_textmodulethicknessthrough" :key="index">
                                        <span v-if='index === 0'>
                                            <!-- if with children -->
                                            <vsa-item v-if="text_module_thickness.product_width_configuration_name || text_module_thickness.product_thickness_configuration || text_module_thickness.product_degree_name">

                                                <vsa-heading>
                                                    <h4 v-if="modulethicknessthrough.product_text_module_width_name">{{ modulethicknessthrough.product_text_module_width_name }}&nbsp;x&nbsp;{{ modulethicknessthrough.product_thickness_name }}&nbsp;mm</h4>
                                                </vsa-heading>

                                                <vsa-content>
                                                    <div class="inner-last-group-wrap">
                                                        <p v-for="text_module_thickness in modulethicknessthrough.p_textmodulethicknessthrough" :key="text_module_thickness.id">
                                                            <span v-if="text_module_thickness.product_width_configuration_name">
                                                                {{ text_module_thickness.product_width_configuration_name }}
                                                            </span>
                                                            <span v-if="text_module_thickness.product_thickness_configuration">
                                                                <span v-if="text_module_thickness.product_width_configuration_name">
                                                                    &nbsp;x&nbsp;
                                                                </span>
                                                                {{ text_module_thickness.product_thickness_configuration_name }}&nbsp;mm
                                                            </span>
                                                            <span v-if="text_module_thickness.product_degree_name">
                                                                <span v-if="text_module_thickness.product_thickness_configuration">
                                                                    &nbsp;/&nbsp;
                                                                </span>
                                                                {{ text_module_thickness.product_degree_name }}°
                                                            </span>
                                                            <span v-if="text_module_thickness.product_width_configuration_name || text_module_thickness.product_thickness_configuration || text_module_thickness.product_degree_name">
                                                                <span v-if="text_module_thickness.superscript_number">
                                                                    <sup>{{ text_module_thickness.superscript_number }}</sup>
                                                                </span>
                                                            </span>
                                                        </p>
                                                        <!--
                                                        <div v-for="sample_information in modulethicknessthrough.p_sampleinformationthrough" :key="sample_information.id">
                                                            <p>{{ sample_information.product_sample_information.text }}</p>
                                                        </div>
                                                        <div v-for="option_alternative in modulethicknessthrough.p_optionalternativethrough" :key="option_alternative.id">
                                                            <p>Alternative:&nbsp;{{ option_alternative.product_option_alternative.product.label }}&nbsp;{{ option_alternative.product_option_alternative.note }}</p>
                                                        </div>
                                                            -->
                                                    </div>
                                                </vsa-content>
                                            </vsa-item>
                                            <!-- if WITHOUT children -->
                                            <div v-else>
                                                <div class="heading-without-children">
                                                    <h4 v-if="modulethicknessthrough.product_text_module_width_name">
                                                        {{ modulethicknessthrough.product_text_module_width_name }}&nbsp;x&nbsp;{{ modulethicknessthrough.product_thickness_name }}&nbsp;mm<span v-if="text_module_thickness.superscript_number"><sup>&nbsp;{{ text_module_thickness.superscript_number }}</sup></span>
                                                    </h4>
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                </vsa-list>

                            </vsa-content>
                        </vsa-item>
                    </vsa-list>

                    <h2 class="product-static-title">Product Information</h2>

                    <span v-if="product.available_width_table_one || product.available_width_table_two">
                        <vsa-list class="grouped_by_configuration">
                            <vsa-item>
                                <vsa-heading>
                                    <h3>Available Widths</h3>
                                </vsa-heading>
                                <vsa-content>

                                    <div v-if="product.available_width_table_one">
                                        <h4 class="table-title">{{ product.available_width_table_one.table_title }}</h4>
                                        <table v-if="product.available_width_table_one.table_rows" class="available-widths-table">
                                            <tr v-for="(col, index) in product.available_width_table_one.table_rows" :key="index">
                                                <th v-if="index === 0 && col.col_0" class="first-th">{{ col.col_0 }}</th><td v-if="index !== 0 && col.col_0" class="first-td">{{ col.col_0 }}</td>
                                                <th v-if="index === 0 && col.col_1">{{ col.col_1 }}</th><td v-if="index !== 0 && col.col_1">{{ col.col_1 }}</td>
                                                <th v-if="index === 0 && col.col_2">{{ col.col_2 }}</th><td v-if="index !== 0 && col.col_2">{{ col.col_2 }}</td>
                                                <th v-if="index === 0 && col.col_3">{{ col.col_3 }}</th><td v-if="index !== 0 && col.col_3">{{ col.col_3 }}</td>
                                                <th v-if="index === 0 && col.col_4">{{ col.col_4 }}</th><td v-if="index !== 0 && col.col_4">{{ col.col_4 }}</td>
                                                <th v-if="index === 0 && col.col_5">{{ col.col_5 }}</th><td v-if="index !== 0 && col.col_5">{{ col.col_5 }}</td>
                                                <th v-if="index === 0 && col.col_6">{{ col.col_6 }}</th><td v-if="index !== 0 && col.col_6">{{ col.col_6 }}</td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div v-if="product.available_width_table_two">
                                        <h4 class="table-title">{{ product.available_width_table_two.table_title }}</h4>
                                        <table v-if="product.available_width_table_two.table_rows" class="available-widths-table">
                                            <tr v-for="(col, index) in product.available_width_table_two.table_rows" :key="index">
                                                <th v-if="index === 0 && col.col_0" class="first-th">{{ col.col_0 }}</th><td v-if="index !== 0 && col.col_0" class="first-td">{{ col.col_0 }}</td>
                                                <th v-if="index === 0 && col.col_1">{{ col.col_1 }}</th><td v-if="index !== 0 && col.col_1">{{ col.col_1 }}</td>
                                                <th v-if="index === 0 && col.col_2">{{ col.col_2 }}</th><td v-if="index !== 0 && col.col_2">{{ col.col_2 }}</td>
                                                <th v-if="index === 0 && col.col_3">{{ col.col_3 }}</th><td v-if="index !== 0 && col.col_3">{{ col.col_3 }}</td>
                                                <th v-if="index === 0 && col.col_4">{{ col.col_4 }}</th><td v-if="index !== 0 && col.col_4">{{ col.col_4 }}</td>
                                                <th v-if="index === 0 && col.col_5">{{ col.col_5 }}</th><td v-if="index !== 0 && col.col_5">{{ col.col_5 }}</td>
                                                <th v-if="index === 0 && col.col_6">{{ col.col_6 }}</th><td v-if="index !== 0 && col.col_6">{{ col.col_6 }}</td>
                                            </tr>
                                        </table>
                                    </div>

                                </vsa-content>
                            </vsa-item>
                        </vsa-list>
                    </span>

                    <vsa-list class="grouped_by_configuration">
                        <vsa-item>
                            <vsa-heading>
                                <h3>Advantages</h3>
                            </vsa-heading>
                            <vsa-content>
                                <p class="advantages-wrapper">{{ product.advantages }}</p>
                            </vsa-content>
                        </vsa-item>
                    </vsa-list>

                    <vsa-list class="grouped_by_configuration">
                        <vsa-item>
                            <vsa-heading>
                                <h3>Sample information</h3>
                            </vsa-heading>
                            <vsa-content>
                                <div v-for="info_superscript in product.product_sample_information_superscript" :key="info_superscript.id">
                                    <p class=""><sup>{{ info_superscript.superscript_number }}</sup>&nbsp;{{ info_superscript.sample_information_text }}</p>
                                </div>
                            </vsa-content>
                        </vsa-item>
                    </vsa-list>
                </div>
            </div>
        </div>
        <BottomNavBar></BottomNavBar>
    </div>
</template>

<script>
    import BottomNavBar from '../components/BottomNavbar'
    export default {
        components: {
            BottomNavBar
        },
        props: {
            productId: String,
        },
    };
</script>

<style>
    /*@import './assets/styles/vars.css';*/
    /* 
        orange: #ed6f2d 
        grey: #ededed
        grey-font: #4a4a4a
    */
    .product-detail {
        padding: 20px;
    }

    .product-detail .product-header-img {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 200px;
        width: 100%;
    }

    .product-detail .product-main-title {
        background-color: #ededed;
        border-bottom: 2px solid #ed6f2d;
        text-align: center;
        padding: 10px;
        margin-top: 0;
        margin-bottom: 40px;
    }

    .product-detail .product-static-title {
        text-transform: uppercase;
        font-size: 20px;
    }

    .product-static-subtitle {
        font-weight: bold;
    }

    .product-detail h3 {
        font-size: 20px;
    }

    .inner-last-group-wrap {
        padding-left: 20px;
        padding-right: 20px;
    }

    .advantages-wrapper {
        white-space: pre-line;
        line-height: 2.1;
    }

    .vsa-list {
        border: 0 !important;
        --vsa-heading-padding: 3px 10px !important;
        --vsa-content-padding: 3px 10px !important;
        --vsa-default-icon-size: .7 !important;
    }

    .vsa-item__content {
        /*background-color: #ededed;*/
    }

    .vsa-item__trigger{
        /*padding-left: 0;*/
        line-height: .8 !important;
    }

    .vsa-item--is-active .vsa-item__heading,
    .vsa-item:not(:last-of-type) {
        border: 0 !important;
    }

    .vsa-item__trigger__icon--is-default::after,
    .vsa-item__trigger__icon--is-default::before {
        background-color: #ed6f2d !important;
    }

    .vsa-item__trigger:focus,
    .vsa-item__trigger:hover {
        background-color: #ed6f2d !important;
    }

    .vsa-item__trigger[aria-expanded="true"] {
        background-color: #ed6f2d !important;
        color: #fff !important;
    }

    .vsa-item__trigger[aria-expanded="true"] .vsa-item__trigger__icon--is-default::after,
    .vsa-item__trigger[aria-expanded="true"] .vsa-item__trigger__icon--is-default::before {
        background-color: #fff !important;
    }

    .vsa-item__trigger:focus .vsa-item__trigger__icon--is-default::after,
    .vsa-item__trigger:focus .vsa-item__trigger__icon--is-default::before,
    .vsa-item__trigger:hover .vsa-item__trigger__icon--is-default::after,
    .vsa-item__trigger:hover .vsa-item__trigger__icon--is-default::before {
        background-color: #fff !important;
    }

    .technical-drawing-img {
        margin: 20px 0;
    }

    .degree-nr {
        padding-left: 20px;
    }

    .heading-without-children {
        font-weight: 700;
        line-height: 1.15;
        font-size: 1.25rem;
        padding: 3px 10px;
    }

    .heading-without-children h4 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .table-title {
        text-align: left;
        font-weight: 700;
        font-size: 1.25rem;
        margin-bottom: 10px;
    }

    .available-widths-table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }

    .available-widths-table th.first-th {
        text-align: left;
    }

    .available-widths-table td.first-td {
        text-align: right;
    }

    .available-widths-table td,
    .available-widths-table th {
        border: 1px solid #4a4a4a;
        padding: 8px;
        font-weight: bold;
    }

    .available-widths-table tr:nth-child(odd){
        background-color: #ededed;
    }

    .available-widths-table th {
        padding-top: 12px;
        padding-bottom: 12px;
        color: #4a4a4a;
    }

    @media screen and (max-width: 700px) {

        .product-detail .product-main-title {
            font-size: 22px;
        }

        .vsa-list {
            --vsa-heading-padding: 3px 8px !important;
            --vsa-content-padding: 3px 8px !important;
        }

        .product-detail .product-static-title {
            font-size: 18px;
        }

        .vsa-item__trigger__content {
            font-size: 16px !important;
        }

        .heading-without-children {
            font-size: 16px;
        }

        .table-title {
            font-size: 16px;
        }

        .available-widths-table td,
        .available-widths-table th {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 420px) {

        .product-detail {
            padding: 10px;
        }

        .available-widths-table td,
        .available-widths-table th {
            font-size: 11px;
            padding-right: 2px;
            padding-left: 2px;
            font-weight: normal;
        }
    }

</style>
