//https://github.com/nishant-boro/django_rest-vuejs-auth
//https://blog.logrocket.com/building-pwa-vue/

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import axios from 'axios';
import store from './store';
import IdleVue from 'idle-vue';
import router from "./router";
import './registerServiceWorker';
import locales from "./locales/locales";

import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

Vue.prototype.$http = axios;

Vue.use(VueI18n);

Vue.config.productionTip = false;

const eventsHub = new Vue()

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 720000
}) // sets up the idle time,i.e. time left to logout the user on no activity
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    // if any of the routes in ./router.js has a meta named 'requiresAuth: true'
    // then check if the user is logged in before routing to this path:
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            next({ name: 'LoginView' })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresLogged)) {
    // else if any of the routes in ./router.js has a meta named 'requiresLogged: true'
    // then check if the user is logged in; if true continue to home page else continue routing to the destination path
    // this comes to play if the user is logged in and tries to access the login/register page
        if (store.getters.loggedIn) {
            next({ name: 'HomeView' })
        } else {
            next()
        }
    } else {
        next()
    }
})


const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'en',
    messages: locales
})

function groupBy(array, key){
    const result = {}
    array.forEach(item => {
        if (!result[item[key]]){
            result[item[key]] = []
        }
        result[item[key]].push(item)
    })
    return result
}

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

Vue.use(VueSimpleAccordion, {
    tags: {
        list: "dl",
        list__item: "div",
        item__heading: "dt",
        heading__content: "span",
        heading__icon: "span",
        item__content: "dd"
    },
    roles: {
        presentation: false,
        heading: false,
        region: true
    },
    level: 4,
    transition: "vsa-collapse",
    initActive: false,
    forceActive: undefined,
    autoCollapse: false,
    onHeadingClick: () => {},
    navigation: true
});

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    data() {
        return {
            products: [''],
            blades: [''],
            logversions: [''],
        }
    },
    methods: {
        async getData() {
            try {
                // CHECK AXIOS-BASE.JS AND rest.py TOO
                const response = await this.$http.get('https://daetwyler.e621gmbh.ch/products-api/');
                //const response = await this.$http.get('https://daetwyler.e621gmbh.ch/products-api/');
                //const response = await this.$http.get('http://127.0.0.1:8080/products-api/');
                this.products = response.data;
                //console.log('api in here');
                //console.log(this.products);
                const response_blades = await this.$http.get('https://daetwyler.e621gmbh.ch/blades-api/');
                //const response_blades = await this.$http.get('https://daetwyler.e621gmbh.ch/blades-api/');
                //const response_blades = await this.$http.get('http://127.0.0.1:8080/blades-api/');
                this.blades = response_blades.data;
                //console.log(this.blades);
                const response_logversions = await this.$http.get('https://daetwyler.e621gmbh.ch/log-version-api/');
                //const response_logversions = await this.$http.get('https://daetwyler.e621gmbh.ch/log-version-api/');
                //const response_logversions = await this.$http.get('http://127.0.0.1:8080/log-version-api/');
                this.logversions = response_logversions.data;
                //console.log(this.logversions);
            } catch (error) {
                console.log('api-ERROR in here');
                //console.log(error);
            }
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
    },
    computed: {
        confsByProductGroup() {
            return groupBy(this.products, 'id')
        },
        confsByBladeGroup() {
            return groupBy(this.blades, 'id')
        },
        confsByLogVersions() {
            return groupBy(this.logversions, 'id')
        },
        filterDoublicates() {
            return [...new Map(this.blades.map(item => [item.products.degree_name, item])).values()];
        },
        locales() {
            return this.$i18n.availableLocales.map(local => {
                return {name: this.$t('locales.' + local), val: local}
            });
        }
    },
    created() {
        this.getData();
    },
}).$mount('#app')
