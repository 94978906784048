const en = {
    locales: {
        fr: 'Français',
        en: 'Anglais'
    },
    home: {
        welcome: "Welcome",
        welcome_sub: "MDC DOCTOR BLADES PRODUCT INFORMATION",
        imprint_title: "Imprint and Privacy",
        imprint_text: "Please take note of our privacy policy when using the Daetwyler SwissTec App, which can be found on the website. By using this application, you acknowledge and agree to the privacy policy of the Daetwyler Group.",
        contact_title: "Contact",
        contact_text: "Daetwyler SwissTec AG | Flugplatz | 3368 Bleienbach | Switzerland",
        contact_phone: "P +41 62 919 31 31",
        contact_email: "swisstec@daetwyler.com",
        contact_website: "swisstec.daetwyler.com",
    },
    reset: {
        reset_instruction_title: "Forgot your password?",
        reset_instruction_text: "Enter your email address below, and we'll email instructions for setting a new one.",
        reset_instruction_success_text: "Check your inbox for a link to reset your password. If an email doesn't appear within a few minutes, check your spam folder. \n NOTE: You won't receive any email, if your account do not exist nor is activated",
        reset_instruction_error_text: "A error occured while processing your request.",
        reset_send_btn_text: "Send email",
        reset_back_to_home: "Back to home-screen",
    },
    register: {
        register_create_title: "Create Account",
        register_create_btn_text: "Create account",
        register_already_have_an_account: "Already have an account?",
        register_reset_password: "Reset password",
        register_complete_text: "Registration complete. We will contact you soon by email with instructions on how to activate your account.",
        register_instruction_error_text: "A error occured while processing your request.",

        register_form_first_name: "First Name",
        register_form_last_name: "Last Name",
        register_form_company: "Company",
        register_form_address: "Address",
        register_form_phone: "Phone",
        register_form_email: "Email",
        register_form_password: "Password",
        register_form_confirm_password: "Confirm Password",
        register_form_email_format_txt: "Password must contain at least one Uppercase, one Lowercase, one Number and one Special Chacter",
        register_form_btn_register: "Register"
    },
    user: {
        data: {
            mail: "en in here",
        },
    }
};

export default en;
