//import de from "@/locales/de";
//import fr from "@/locales/fr";
import en from "@/locales/en";

const locales = {
    //de: de,
    //fr: fr,
    en: en
}

export default locales