<template>
    <div class="product-list">
        <div class="list-view">
            <products-product :products="this.$root.products" />
            <!--<router-view></router-view>-->
        </div>
        <BottomNavBar></BottomNavBar>
    </div>
</template>

<script>
    import ProductsProduct from "@/components/ProductsProduct.vue"
    import BottomNavBar from '../components/BottomNavbar'
    export default {
        components: {
            ProductsProduct,
            BottomNavBar
        },
    }
</script>