<template>
    <div class="base-form login-form">
        <HeaderImage></HeaderImage>
        <div class="content-wrap">
            <!--<h2 v-for="mod in APIData" :key="mod.id">{{ mod.name }}</h2>-->
            <h1 class="main-title">{{ $t("home.welcome") }}.</h1>
            <h2 class="sub-title">{{ $t("home.welcome_sub") }}</h2>
            <div class="form-lg">
                <form v-on:submit.prevent="loginUser">
                    <label for="user">Username</label>
                    <input type="text" name="username" id="user" v-model="username" placeholder="Email">
                    <label for="pass">Password</label>
                    <input type="password" name="password" id="pass" v-model="password" placeholder="Password">
                    <h2 class="formerror" v-if="wrongCred">Wrong credentials entered! Please enter your correct details.</h2>
                    <button class="orange-btn login-btn" type="submit">Login</button>
                </form>
            </div>

            <div class="footer-links-wrap">
                <button class="transparent-btn reset-psw-btn btn-inline-block">
                    <router-link :to = "{ name:'PasswordResetView' }">Reset Password</router-link>
                </button>
                <span class="btn-inline-block">  |  </span>
                <button class="transparent-btn register-new-user-btn btn-inline-block">
                    <router-link :to = "{ name:'RegisterView' }">Register</router-link>
                </button>
            </div>
            <img class="main-logo" alt="daetwyler logo" src="../assets/logo.jpg">
        </div>
    </div>
</template>

<script>
    //import NavBar from '../components/Navbar'
    import HeaderImage from '../components/HeaderImage'
    export default {
        name: 'login-view',
        components: {
            //NavBar,
            HeaderImage
        },
        data () {
            return {
                username: '',
                password: '',
                wrongCred: false // activates appropriate message if set to true
            }
        },
        methods: {
            loginUser () { // call loginUSer action
                this.$store.dispatch('loginUser', {
                    username: this.username,
                    password: this.password
                })
                .then(() => {
                    this.wrongCred = false
                    this.$router.push({ name: 'HomeView' })
                })
                .catch(err => {
                    console.log(err)
                    this.wrongCred = true // if the credentials were wrong set wrongCred to true
                })
            }
        }
    }
</script>

<style scoped>

.main-bottom-navigation {
    display: none;
}

.reset-psw-btn {
    margin-top: 40px;
}
 
</style>