<template>
  <nav class="main-bottom-navigation">
      <router-link @click.native="$scrollToTop" class="main-bottom-navigation-link" to="/"><img class="main-bottom-navigation-icon" src="../assets/svg/home.svg"/></router-link>
      <router-link @click.native="$scrollToTop" class="main-bottom-navigation-link" to="/products"><img class="main-bottom-navigation-icon" src="../assets/svg/configurator.svg"/></router-link>
      <router-link @click.native="$scrollToTop" class="main-bottom-navigation-link" to="/blades"><img class="main-bottom-navigation-icon" src="../assets/svg/blade.svg"/></router-link>
  </nav>
</template>

<script>
    export default {
        name: 'BottomNavBar',
    }
</script>