<template>
    <div class="header-img-wrap">
        <div class="header-img" :style="headerImg"></div>
    </div>
</template>

<script>
    export default {
        name: 'HeaderImage',
        data () {
            return {
                headerImg: {
                    backgroundImage: `url(${require('@/assets/header_optilife_plus.jpg')})`
                },
            }
        }
    }
</script>
<style>

.header-img-wrap {
    height: 500px;
    width: 100%;
    border-bottom: 2px solid #ed6f2d;
}
.header-img-wrap .header-img {
    display: block;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1124px) {

    .header-img-wrap {
        height: 400px;
    }
}

@media screen and (max-width: 700px) {

    .header-img-wrap {
        height: 200px;
    }
}

</style>